import { ColorsInterface } from '../types'

/* tslint:disable object-literal-sort-keys */
const colors: ColorsInterface = {
  primary: {
    xxlight: 'hsla(300, 12%, 85%, 1)',
    xlight: 'hsla(300, 13%, 75%, 1)',
    light: 'hsla(300, 12%, 56%, 1)',
    mlight: 'hsla(300, 16%, 50%, 1)',
    medium: 'hsla(300, 28%, 34%, 1)',
    mdark: 'hsl(300, 50%, 20%, 1)',
    dark: 'hsla(300, 49%, 16%, 1)',
    xdark: 'hsla(300, 50%, 11%, 1)',
    xxdark: 'hsla(300, 49%, 5%, 1)',
  },
  accent: {
    xxlight: 'hsla(156, 40%, 88%, 1)',
    xlight: 'hsla(156, 40%, 80%, 1)',
    light: 'hsla(156, 40%, 71%, 1)',
    mlight: 'hsla(156, 40%, 63%, 1)',
    medium: 'hsla(157, 40%, 55%, 1)',
    mdark: 'hsla(156, 33%, 45%, 1)',
    dark: 'hsla(157, 32%, 35%, 1)',
    xdark: 'hsla(156, 32%, 20%, 1)',
    xxdark: 'hsla(158, 31%, 10%, 1)',
  },
  neutral: {
    xxlight: 'hsla(38, 24%, 95%, 1)',
    xlight: 'hsla(38, 21%, 88%, 1)',
    light: 'hsla(39, 16%, 76%, 1)',
    mlight: 'hsla(39, 14%, 66%, 1)',
    medium: 'hsla(39, 12%, 58%, 1)',
    mdark: 'hsla(40, 12%, 45%, 1)',
    dark: 'hsla(41, 13%, 34%, 1)',
    xdark: 'hsla(41, 15%, 20%, 1)',
    xxdark: 'hsla(41, 21%, 5%, 1)',
  },
  success: {
    xxlight: 'hsla(123, 23%, 82%, 1)',
    xlight: 'hsla(122, 23%, 70%, 1)',
    light: 'hsla(122, 23%, 58%, 1)',
    mlight: 'hsla(123, 28%, 45%, 1)',
    medium: 'hsla(123, 46%, 34%, 1)',
    mdark: 'hsla(123, 46%, 28%, 1)',
    dark: 'hsla(122, 45%, 22%, 1)',
    xdark: 'hsla(123, 46%, 15%, 1)',
    xxdark: 'hsla(123, 46%, 9%, 1)',
  },
  warning: {
    xxlight: 'hsla(38, 100%, 96%, 1)',
    xlight: 'hsla(38, 92%, 90%, 1)',
    light: 'hsla(38, 86%, 80%, 1)',
    mlight: 'hsla(38, 83%, 70%, 1)',
    medium: 'hsla(38, 100%, 63%, 1)',
    mdark: 'hsla(38, 81%, 45%, 1)',
    dark: 'hsla(38, 86%, 33%, 1)',
    xdark: 'hsla(38, 90%, 21%, 1)',
    xxdark: 'hsla(38, 100%, 10%, 1)',
  },
  danger: {
    xxlight: 'hsla(5, 100%, 89%, 1)',
    xlight: 'hsla(5, 98%, 80%, 1)',
    light: 'hsla(5, 95%, 73%, 1)',
    mlight: 'hsla(5, 93%, 66%, 1)',
    medium: 'hsla(5, 90%, 50%, 1)',
    mdark: 'hsla(5, 93%, 40%, 1)',
    dark: 'hsla(5, 95%, 33%, 1)',
    xdark: 'hsla(5, 98%, 21%, 1)',
    xxdark: 'hsla(5, 100%, 10%, 1)',
  },
}

export default colors
