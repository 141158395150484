import 'sanitize.css/sanitize.css'
import { createGlobalStyle } from 'styled-components'

import fonts, { fallbackFont, primaryFont } from './fonts'

const GlobalStyles = createGlobalStyle`
    body {
        font-family: ${fallbackFont};
        font-size: 16px;
        font-weight: 400;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    body.primary-font-loaded {
        font-family: ${primaryFont}, ${fallbackFont};
        font-weight: ${fonts.weights.regular};
    }
`

export default GlobalStyles
