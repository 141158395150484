import React, { FunctionComponent } from 'react'
import Helmet from 'react-helmet'

interface SEOProps {
  description?: string
  lang?: string
  title?: string
}

const titleSuffix = 'Sunburst Software'

const SEO: FunctionComponent<SEOProps> = ({
  description,
  lang = 'en',
  title,
}) => {
  const fullTitle = title ? `${title} | ${titleSuffix}` : titleSuffix
  const descriptionTags = description
    ? [
        <meta content={description} key="desc" name="description" />,
        <meta content={description} key="og:desc" name="og:description" />,
        <meta
          content={description}
          key="twitter:desc"
          name="twitter:description"
        />,
      ]
    : null
  return (
    <Helmet>
      <html lang={lang} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      {descriptionTags}
      <meta name="og:title" content={fullTitle} />,
      <meta name="twitter:title" content={fullTitle} />,
      <title>{fullTitle}</title>
    </Helmet>
  )
}

export default SEO
