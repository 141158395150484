import React, { FunctionComponent } from 'react'
import Helmet from 'react-helmet'

import '@sunburst/shared-logo/assets/favicon'
import { GlobalStyles } from '@sunburst/shared-styles'

const Page: FunctionComponent = ({ children }) => {
  const noRobots =
    process.env.DEPLOYMENT_ENV !== 'production' ? (
      <meta name="robots" content="noindex" />
    ) : null
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png?v=7koqQxRr9g"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png?v=7koqQxRr9g"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png?v=7koqQxRr9g"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg?v=7koqQxRr9g"
          color="#6f3e6f"
        />
        <link rel="shortcut icon" href="/favicon.ico?v=7koqQxRr9g" />
        <meta name="msapplication-TileColor" content="#6f3e6f" />
        <meta name="theme-color" content="#6f3e6f" />
        {noRobots}
      </Helmet>
      {children}
    </>
  )
}

export default Page
