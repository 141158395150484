/* tslint:disable object-literal-sort-keys */
export default {
  sizing: {
    sm1: '4px',
    sm2: '8px',
    sm3: '12px',
    sm4: '16px',
    md1: '24px',
    md2: '32px',
    md3: '48px',
    md4: '64px',
    lg1: '96px',
    lg2: '128px',
    lg3: '192px',
    lg4: '256px',
    xl1: '384px',
    xl2: '512px',
    xl3: '640px',
    xl4: '768px',
  },
  breakpoints: {
    phoneDown: '@media screen and (max-width: 767px)',
    tabletDown: '@media screen and (max-width: 991px)',
    desktopDown: '@media screen and (max-width: 1199px)',
    tabletUp: '@media screen and (min-width: 768px)',
    desktopUp: '@media screen and (min-width: 992px)',
    largeDesktopUp: '@media screen and (min-width: 1200px)',
  },
}
