import FontFaceObserver from 'fontfaceobserver'
import { css } from 'styled-components'

export const primaryFont = 'Montserrat'
export const fallbackFont =
  'system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

if (typeof document !== 'undefined') {
  const primaryFontObserver = new FontFaceObserver(primaryFont)
  primaryFontObserver.load().then(() => {
    document.body.classList.add('primary-font-loaded')
  })
}

/* tslint:disable object-literal-sort-keys */
export default {
  sizes: {
    xxs: '12px',
    xs: '14px',
    sm: '16px',
    md: '18px',
    ml: '20px',
    lg: '24px',
    xl: '30px',
    xxl: '36px',
    xxxl: '48px',
    xxxxl: '60px',
    xxxxxl: '72px',
  },
  weights: {
    light: 300,
    regular: 500,
    bold: 700,
  },
  lineHeights: {
    tight: 1,
    regular: 1.5,
    loose: 2,
  },
  transforms: {
    truncate: css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    uppercase: css`
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: 700;
    `,
  },
}
